@import 'variables/colors';

@import 'variables/breakpoints';

@import 'src/scss/layout';

@import 'variables/variables';

@import 'animations';

@import 'fonts';

$menu-icon-size: 23px;
$menu-text-height: 20px;

%menu-item {
  height: 75px;
  padding: 0 20px;
  text-align: left;
}

.menu-item-selected {
  @extend %menu-item;

  background-color: $brand-primary;
  color: $white;
}

.menu-item-not-selected {
  @extend %menu-item;
  &.menu-item-hovered {
    background-color: rgba($brand-primary-rgb, 0.6);
    color: $white;
  }
}


.menu-text {
  @extend .small-text;
}

.menu-icon {
  height: $menu-icon-size;
  width: $menu-icon-size;
}

.arrow {
  @extend %rotate;
}

.selected-text {
  color: $brand-primary;
}

.sub-menu-container {
  padding-left: 40px;
  color: $brand-primary;
}

.sub-item {
  @extend .smaller-text;
  height: 60px;
}

.sub-item-icon {
  height: 15px;
  width: 15px;
}
