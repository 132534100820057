@import 'variables/breakpoints';

@import 'animations';

@import 'layout';

@import 'margins';

@import 'components';

@import 'variables/variables';

@import 'inputs';

@import 'fonts';

@import 'coreSettings';

@import 'buttons';

* {
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: sans-serif;
  margin: 0;
  padding: 0;

  &:focus {
    outline: none;
  }
}

body {
  background-color: rgba(0,0,0,0.1);
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
label,
input,
a,
button,
select,
textarea {
  color: inherit;
  display: block;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
}

input,
select,
textarea,
button {
  border: 0;
  border-radius: 0;
}

button {
  background: transparent;
  cursor: pointer;
}

a {
  text-decoration: none;
}

ul,
ol {
  list-style: none;
}

.anchor {
  position: relative;
}

::-webkit-input-placeholder {
  color: $black;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
