$transition-duration: 290ms;
$no-transition: 0ms;
$transition-function: cubic-bezier(0.790, 0.010, 0.375, 0.995);
$default-border-radius: 8px;
$max-width-modal-default: 660px;
$form-border-radius: 0 10px 10px;
$arrow-size: 48px;
$small-arrow-size: 30px;
$error-size: 19px;
$small-error-width: 15px;
$small-error-height: 18px;
$draft-size: 8px;
$small-loading: 50px;
$modal-z-index: 5;
$tooltip-z-index: $modal-z-index + 3;
$menu-width: calc(min(250px, 30%));
$small-menu-width: calc(min(200px, 30%));
