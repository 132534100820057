@import 'variables/colors';

@import 'variables/breakpoints';

@import 'variables/shadows';

@import 'variables/variables';

@import 'src/scss/layout';

.side-bar-container {
  @extend .column;
  @extend .center;

  background: $pale-blue;
  box-shadow: $box-shadow-2;
  height: 100%;
  left: 0;
  position: fixed;
  width: $menu-width;
  z-index: 10;
  @include lg {
    width: $small-menu-width;
  }
}

.logo {
  height: 60px;
  margin-bottom: 10px;
  margin-top: 10px;
  object-fit: scale-down;
  width: 150px;
}
