@import 'src/scss/variables/variables';
@import 'src/scss/variables/colors';
@import 'src/scss/variables/gradients';
@import 'src/scss/variables/shadows';

.unlabeled-field {
  margin-top: 30px;
}

.divider {
  display: block;
  background-color: $divider;
  width: 95%;
  height: 2px;
}

.checkbox {
  width: 45px;
  height: 38px;
  border-radius: 8px;
  background: $input-gradient, $white;
  box-shadow: $input-shadow;
}

.checkbox-container {
  height: 40px;
  padding-bottom: 2px;
};

.card-container {
  background-color: $white;
  padding: 30px;
}

.full-width {
  width: 100%;
}

.half-width {
  width: 50%;
}

.quarter-width {
  width: 25%;
}

.full-height {
  height: 100%;
}

.half-height {
  height: 50%;
}

@media screen and (max-width: $breakpoint-sm) {
  .full-width-mobile {
    width: 100%;
  }
}
