@import 'variables/variables';

@import 'variables/colors';

@import 'variables/breakpoints';

.container-site {
  transition: all $transition-duration $transition-function;
  width: 100%;

  &.activeProfileMenu {
    background-color: $white;
    -webkit-filter: blur(2px);
    -moz-filter: blur(2px);
    -ms-filter: blur(2px);
    -o-filter: blur(2px);
    filter: blur(2px);
  }
}

.content-container {
  width: 100%;
  height: 100%;

  &.padded {
    padding-left: $menu-width;
    @include lg {
      padding-left: $small-menu-width;
    }
  }

}

.main-content {
  height: 100%;
  padding-top: 70px;
}
