@import 'variables/colors';

@import 'variables/shadows';

@import 'variables/breakpoints';

.navbar-container {
  background: $navbar-color;
  box-shadow: $box-shadow-2;
  font-size: 18px;
  font-weight: bold;
  width: 100%;
  z-index: 100;
}

.navbar {
  height: 75px;
  padding: 0 40px;
  position: relative;
}

.logout {
  font-size: 16px;
  font-weight: normal;
  height: 35px;
  width: 150px;
}

.user {
  font-size: 24px;
  font-weight: 200;
  text-transform: capitalize;
}

.divisor {
  background-color: black;
  height: 40px;
  padding: 20px 0;
  width: 1px;
}

.info-container {
  align-items: center;
  display: flex;
  justify-content: space-between;
  >* {
    margin: 0 5px;
  }
}
