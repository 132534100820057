@import 'variables/colors';

@import 'variables/gradients';

@import 'variables/shadows';
$ease-in-transition: all 0.4s ease-in-out;

%button {
  background-size: 250% 100%;
  border-radius: 60px;
  box-shadow: $button-box-shadow;
  color: $white;
  height: 40px;
  -webkit-transition: $ease-in-transition;
  -moz-transition: $ease-in-transition;
  -o-transition: $ease-in-transition;
  transition: $ease-in-transition;

  &:hover {
    background-position: 100% 0;
  }

  &:focus {
    outline: none;
  }
}

.button-primary {
  @extend %button;

  background-color: $brand-primary;
  background-image: $button-primary-gradient;
}

.button-primary-loading {
  @extend .button-primary;

  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  transition: none;
  background-image: none;
}

.button-secondary {
  @extend %button;

  background-color: $divider;
  background-image: $button-cancel-gradient;
  color: $title-black;
}

.button-secondary-loading {
  @extend .button-secondary;

  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  transition: none;
  background-image: none;
}


.button-tertiary {
  @extend %button;

  background-color: $add-button;
  background-image: $button-tertiary-gradient;
}

.btn-hover {
  background-size: 300% 100%;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;

  &:hover {
    background-position: 100% 0;
  }

  &:focus {
    outline: none;
  }
}
