@import 'variables/colors';
@import 'variables/shadows';
@import 'fonts';
@import 'variables/gradients';

.form-input {
  @extend .small-text;

  background: $input-gradient, $white;
  border: 1px solid $azure;
  border-radius: 20px;
  box-shadow: $input-shadow;
  font-size: 16px;
  min-height: 40px;
  line-height: 20px;
  padding: 5px 20px;
  width: 100%;

  &.input-error {
    border-color: $valencia;
  }

  &.disabled { 
    background-color: $disabled-input;
  }
}

.no-border-input {
  @extend .form-input;

  border: 0;

  &.input-error {
    border: 1px solid $valencia;
  }
}

.error-text {
  @extend .tiny-text;
  color: $valencia;
  padding-left: 15px;
}

input:-webkit-autofill {
  -webkit-box-shadow: $input-shadow;
  box-shadow: $input-shadow;
}

input[type="number"] { 
  -moz-appearance: textfield; 
}
