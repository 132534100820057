@import './variables/breakpoints';
@import './variables/colors';

// Font weights

.bold {
  font-weight: 700;
}

.regular {
  font-weight: 400;
}

.light {
  font-weight: 300;
}

// Format text

.text-center {
  text-align: center;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-justify {
  text-align: justify;
}

.line-through {
  text-decoration: line-through;
}

.large-title {
  font-size: 50px;
  line-height: 60px;
  text-align: center;
  @include md {
    font-size: 40px;
  }
  @include sm {
    font-size: 30px;
  }
}

.title {
  font-size: 36px;
  @include md {
    font-size: 30px;
  }
  @include sm {
    font-size: 24px;
  }
  line-height: 40px;
}

.subtitle {
  font-size: 30px;
  @include md {
    font-size: 24px;
  }
  @include sm {
    font-size: 18px;
  }
  line-height: 35px;
}

.large-text {
  font-size: 24px;
  @include md {
    font-size: 20px;
  }
  @include sm {
    font-size: 16px;
  }
  line-height: 30px;
}

.text {
  font-size: 20px;
  line-height: 24px;
  @include md {
    font-size: 16px;
  }
  @include sm {
    font-size: 14px;
  }
}

.medium-text {
  font-size: 18px;
  line-height: 22px;

  @include md {
    font-size: 16px;
  }

  @include sm {
    font-size: 14px;
  }
}

.small-text {
  font-size: 16px;
  line-height: 20px;
  @include md {
    font-size: 14px;
    line-height: 18px;
  }
  @include sm {
    font-size: 12px;
    line-height: 16px;
  }
}

.smaller-text {
  font-size: 14px;
  line-height: 18px;
  @include md {
    font-size: 12px;
    line-height: 16px;
  }
  @include sm {
    font-size: 10px;
    line-height: 14px;
  }
}

.tiny-text {
  font-size: 12px;
  line-height: 16px;
  @include md {
    font-size: 10px;
    line-height: 14px;
  }
}

.underline {
  text-decoration: underline;
}

.error {
  color: $valencia;
  padding-top: 5px;
  @extend .tiny-text;
}

.no-wrap {
  white-space: nowrap;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}
