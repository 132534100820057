$transparent: transparent;
$white: #FFF;
$black: #000;
$black-tooltip: #222;
$blue: #06F;
$pale-blue: #FAFCFE;
$azure: #35599F;
$valencia: #D34836;
$silver: #C5C5C5;
$gray-default: #808080;
$light-gray: #CCC;
$whisper: #EEE;
$navbar-color: $pale-blue;
$dove-gray: #C2D1E2;
$add-button: #A3A3ED;
$confirm-button: #383874;
$divider: #E9F0F7;
$title-black: #393939;
$disabled-input: #E1E6F0;
$arrow-button: #EAF1F7;
$toast-red-error: #E74C3C;
$forest-green: #228B22;
$dark-yellow: #FDD708;
$warning-yellow: #FFB000;
$dark-orange: #FF8C00;
$modal-background: rgba(0, 0, 0, 0.5);
$brand-primary: var(--brand-primary);
$brand-secondary: var(--brand-secondary);
$brand-primary-rgb: var(--brand-primary-rgb);
