@use 'sass:math';
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$scale: 5;

@media (max-width: $breakpoint-tablet) {
  $scale: 5;
}

@media (min-width: $breakpoint-desktop) {
  $scale: 10;
}

$min-top: 5;
$max-top: 100;
$min-right: 5;
$max-right: 100;
$min-bottom: 5;
$max-bottom: 100;
$min-left: 5;
$max-left: 100;

// Top margins
@if $min-top > 0 {
  $unscaled-max-top: math.div($max-top, $scale);
  $unscaled-min-top: math.div($min-top, $scale);

  @for $i from $unscaled-min-top through $unscaled-max-top {
    .m-top-#{$i} {
      margin-top: #{$i * $scale}px;
    }
  }
}

// Right margins
@if $min-right > 0 {
  $unscaled-max-right: math.div($max-right, $scale);
  $unscaled-min-right: math.div($min-right, $scale);;;

  @for $i from $unscaled-min-right through $unscaled-max-right {
    .m-right-#{$i} {
      margin-right: #{$i * $scale}px;
    }
  }
}

// Bottom margins
@if $min-bottom > 0 {
  $unscaled-max-bottom: math.div($max-bottom, $scale);
  $unscaled-min-bottom: math.div($min-bottom, $scale);

  @for $i from $unscaled-min-bottom through $unscaled-max-bottom {
    .m-bottom-#{$i} {
      margin-bottom: #{$i * $scale}px;
    }
  }
}

// Left margins
@if $min-left > 0 {
  $unscaled-max-left: math.div($max-left, $scale);
  $unscaled-min-left: math.div($min-left, $scale);

  @for $i from $unscaled-min-left through $unscaled-max-left {
    .m-left-#{$i} {
      margin-left: #{$i * $scale}px;
    }
  }
}

.m-top-0 {
  margin-top: 0;
}

.m-right-0 {
  margin-right: 0;
}

.m-bottom-0 {
  margin-bottom: 0;
}

.m-left-0 {
  margin-left: 0;
}
